.footer{
    height: 15vh;
    background-color: black;
    color: white;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 1.5rem;
}
.socials{
    display: flex;
    justify-content: space-around;
    width: 10vw;
}
.socials a{
    color: white;
}

.socials i{
    padding: 0.5rem;
}

@media screen and (orientation:portrait) and (min-width:700px){
    .footer{
        font-size: 1.7rem;
    }
    .socials i{
        padding: 1rem;
    }
}

@media screen and (max-width:450px) {
    .footer{
        font-size: 0.9rem;
        
    }
}


@media screen and (max-width:400px) {
    .footer{
        font-size: 0.7rem;
        
    }
}

@media screen and (max-width:350px) {
    .footer{
        font-size: 0.6rem;
        
    }
}


