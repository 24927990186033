.admin{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    background-size: cover;
    background-image: linear-gradient(to right, rgb(253, 227, 208),rgb(255, 253, 209),rgb(196, 243, 191),rgb(200, 245, 200),rgb(190, 190, 248),rgb(224, 188, 248),rgb(247, 197, 247));
    height:100vh;
    overflow-x: hidden;
}
.admin h1{
    display: flex;
    align-self: center;
    justify-self: center;
    text-align: center;
    font-family: 'Roboto', sans-serif;
}
.admin div{
    display: flex;
    align-self: center;
    justify-self: center;
    margin-bottom: 2%;
}
.admin div label{
    font-weight: bold;
    padding: 0rem 1rem;
    font-size: 18px;
    display: inline-block;
}
.admin input[type="text"]{
    border: 0px;
    border-bottom: 1px solid grey;
    outline: none;
    display: inline-block;
    width: 300px;
  }

.admin input{
    background: none;
}

select, option {
    padding: 0.3rem;
}

#submit{
    background-color: rgb(20, 245, 121);
    box-shadow: 2px 1px 2px 2px rgb(0, 34, 27); 
   
}

#submit:not(:disabled):hover{
    font-size: 1em;
    background-color: rgb(14, 167, 40);
    color: white;
}

#goHome{
    background-color:rgb(162, 0, 255); 
    box-shadow: 2px 1px 2px 2px rgb(57, 2, 68);  
}

#goHome:hover{
    font-size: 1em;
    background-color:rgb(70, 10, 105);
    color: white;
}

.roundButton{
    width: 3.5em;
    height: 3.5em;
    margin: 5px;
    border:1px solid black;
    border-radius: 100%;
    font-weight: bold;
    outline: none; 
    cursor: pointer;
}

.buttons{
    display: flex;
    justify-content: space-between;
}

[data-tooltip]:before {
    content: attr(data-tooltip);
    position: absolute;
    opacity: 0;
    transition: all 0.15s ease;
    padding: 10px;
    color: #333;
    border-radius: 10px;
    box-shadow: 2px 2px 1px silver;    
}

[data-tooltip]:hover:before {
    opacity: 1;
    background: black;
    color: white;
    margin-top: -50px;
    margin-left: 20px;    
}

[data-tooltip]:not([data-tooltip-persistent]):before {
    pointer-events: none;
}

.progress_wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 130vh;

}
.progress{
    width: 30vw;
    height: 75vh;

}

@media screen and (max-width:500px){
    .admin div{
        margin-left: 3%;
        align-self: flex-start;
    }

    .admin input[type="text"] {
        width: 250px;
    }
}

@media screen and (max-width:400px) {
    .admin input[type="text"] {
        width: 200px;
    }
}

@media screen and (max-height:450px){

    .admin div label{
        font-size:14px;
    }
    .admin div{
        margin-left:2%;
        margin-bottom:1%;
    }

}

@media screen and (max-height:600px) {
    .admin{
        height:130vh;
    }
}

@media screen and (max-height:350px){
    .admin{
        height:170vh;
    }
}


@media screen and (max-width:350px){
    .admin input[type="text"]{
        width: 150px;
      }

       select{
        width: 150px;
    }

    .admin div label{
        font-size:14px;
        
    }

    .admin{
        justify-content: flex-start;
        height: 100vh;
    }
}

@media screen and (max-height:300px){
    .admin{
        height: 170vh;
    }
}

