.picsvidswrapper{
    display: flex;
    height: 70vh;
    justify-content: space-evenly;
    align-items: center;
}

.content_boxes{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 65vh;
    width: 45vw;
    padding: 1rem;
    
}

.content_boxes img, .content_boxes video{
    display: inline-block;
    width: 99%;
}

.content_boxes img{
    width: 92%;
}
.content_btn{
    width:20vw;
    background-color:rgb(62, 0, 120)!important;
    color:white;
}
.content_btn:hover{
    background-color: rgb(107, 12, 197)!important;
    color: black;
}

@media screen and (orientation:portrait) {
    .picsvidswrapper{
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 100vh;
    }

    .content_boxes{
        width: 98vw; 
        
    }
    .content_boxes img{
        width: 99%;
    }
    .content_btn{
        width:80vw;
    }
}

@media screen and (orientation:portrait) and (min-height:750px) {
    .picsvidswrapper{
        height: 70vh;
    }
}
@media screen and (orientation:portrait) and (min-height:800px) and (min-width:450px) {
    .picsvidswrapper{
        height: 90vh;
    }
}
@media screen and (orientation:portrait) and (min-height:900px) and (min-width:500px) {
    .picsvidswrapper{
        height: 105vh;
    }
}

@media screen and (orientation:portrait) and (min-height:700px) and (max-height:800px) and (min-width:500px) and (max-width:600px) {
    .picsvidswrapper{
        height: 110vh;
    }
}




