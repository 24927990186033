.failed{
    position: absolute;
    top:10px;
    right:15px;
    background-color: rgb(255, 0, 0);
    color: white;
    font-weight: bold;
    width:auto;
    height:auto;
    padding:10px 15px;
    z-index: 10000;
}

.success{
    position: absolute;
    top:10px;
    right:15px;
    background-color: rgb(0, 255, 0);
    color: white;
    font-weight: bold;
    width:auto;
    height:auto;
    padding:10px 15px;
    z-index: 10000; 
}