#videoWrapper{
    margin-top: 19vh;
    min-height: 70vh;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: space-evenly;
}

.vidWrapperOuter{
  background-color: transparent;
  perspective: 1000px;
  width: 30vw;
  height: 50vh;
  margin-bottom: 1.8rem;
}

.vidWrapperInner{
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
  
}

 .vidWrapperInner:hover{
  transform: rotateY(180deg);
}

.folder_vid{
  text-align: center;
  padding: 0rem 1rem;
}

@media screen and (max-width:1000px) {
    #videoWrapper{
      margin-top: 10px;
    }
   }

   @media screen and (orientation:portrait) {
    #videoWrapper{
      margin-top: 19vh;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .vidWrapperOuter{
      width: 92vw;
    }
   }

   @media screen and (orientation:landscape) and (max-width:1000px) {
    #videoWrapper{
      margin-top: 29vh;
    }
   }

   @media screen and (max-height:400px) {
    #videoWrapper{
      margin-top: 31vh;
    }
  }

  @media screen and (max-height:300px) {
    #videoWrapper{
      margin-top: 41vh;
    }
  }

  @media screen and (max-height:550px) and (orientation:landscape) {
    .vidWrapperOuter{
      height: 60vh;
    }
  }

  @media screen and (max-height:400px) and (orientation:landscape) {
    .vidWrapperOuter{
      height: 65vh;
    }
  }

  @media screen and (max-height:350px) and (orientation:landscape) {
    .vidWrapperOuter{
      height: 70vh;
    }
  }

  @media screen and (max-width:850px) and (orientation:landscape) {
    .vidWrapperOuter{
      width: 45vw;
    }
  }

  .folder_vid h2{
    text-transform: uppercase;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  }

  .folder_vid div{
    font-style: italic;
  }

  .folder_vid_back h2{
    font-family: 'Roboto', sans-serif;
    
  }

  .folder_vid, .folder_vid_back{
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    box-shadow: 0 0 3px rgb(17, 146, 244);
    background-color: rgb(106, 82, 156);
    color: rgb(0, 221, 255);
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
  }

  .folder_vid_back{
    transform: rotateY(180deg);
    box-shadow: 0 0 3px rgb(3, 150, 255);
    background-color: rgb(118, 216, 227);
    color: rgb(120, 20, 252);
  }