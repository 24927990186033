#imageWrapper{
    margin-top: 19vh;
    min-height: 70vh;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: space-evenly;
}

.imgWrapperOuter{
  background-color: transparent;
  perspective: 1000px;
  width: 30vw;
  height: 50vh;
  margin-bottom: 1.8rem;
}

.imgWrapperInner{
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
  
}

 .imgWrapperInner:hover{
  transform: rotateY(180deg);
}

.folder{
  text-align: center;
  padding: 0rem 1rem;
}

@media screen and (max-width:1000px) {
    #imageWrapper{
      margin-top: 10px;
    }
   }

   @media screen and (orientation:portrait) {
    #imageWrapper{
      margin-top: 19vh;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .imgWrapperOuter{
      width: 92vw;
    }
   }

   @media screen and (orientation:landscape) and (max-width:1000px) {
    #imageWrapper{
      margin-top: 29vh;
    }
   }

   @media screen and (max-height:400px) {
    #imageWrapper{
      margin-top: 31vh;
    }
  }

  @media screen and (max-height:300px) {
    #imageWrapper{
      margin-top: 41vh;
    }
  }

  @media screen and (max-height:550px) and (orientation:landscape) {
    .imgWrapperOuter{
      height: 60vh;
    }
  }

  @media screen and (max-height:400px) and (orientation:landscape) {
    .imgWrapperOuter{
      height: 65vh;
    }
  }

  @media screen and (max-height:350px) and (orientation:landscape) {
    .imgWrapperOuter{
      height: 70vh;
    }
  }

  @media screen and (max-width:850px) and (orientation:landscape) {
    .imgWrapperOuter{
      width: 45vw;
    }
  }

  .folder h2{
    text-transform: uppercase;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  }

  .folder div{
    font-style: italic;
  }

  .folder_back h2{
    font-family: 'Roboto', sans-serif;
    
  }

  .folder, .folder_back{
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    box-shadow: 0 0 3px rgb(139, 195, 238);
    background-color: rgb(248, 248, 166);
    color: rgb(38, 132, 203);
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
  }

  .folder_back{
    transform: rotateY(180deg);
    box-shadow: 0 0 3px rgb(255, 255, 3);
    background-color: rgb(125, 187, 234);
    color: rgb(252, 252, 100);
  }

  #loaderWrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }