#compwrapper{
    height: 70vh;
    display: flex;
    justify-content: center;
    align-items: center;
    
}

#img_section{
    display: inline-block;
    margin-right: 1.30rem;
    transition: all 2s ease-in-out;
    
}

#img_section:hover{
 transform: scale(1.03);
}

#desc_section{
    background-color: rgb(248, 248, 248);
    font-family: 'Open Sans', sans-serif;
    overflow-y: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.boxes{
    height: 60vh;
    width: 45vw;
    padding: 0.625rem;
    box-shadow: 0 0 3px #ccc;
    line-height: 1.75rem;
}

@media screen and (orientation:portrait) {
    #compwrapper{
        flex-direction: column;
    }

    #compwrapper{
        height: unset;
    }

    
#desc_section{
    padding: 1.5rem;
    margin-bottom: 1.25rem;
}

.boxes{
    height: auto;
    width: 100vw;
    padding: 0px;
    padding-bottom: 0.5rem;
}

#img_section{
    margin-right: 0px;
    height: 60vh;
}

}
@media screen and (orientation:portrait) and (min-width:700px){
    #desc_section{
        font-size: 1.7rem;
        line-height: 50px;
    }
     #button_pos, #button_neg{
        font-size: 2rem;
     }
}

@media screen and (max-height:400px) {
    #button_pos, #button_neg{
        font-size: 0.7rem;
     }
  }