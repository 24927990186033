.nf_wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height:68vh;
    margin-top: 19vh;
}

.nf_wrapper h1{
    color: rgb(202, 21, 21);
    font-size: 2.343vw;
    padding: 10px;
}

.nf_wrapper h2{
    font-size: 1.756vw;
    padding: 10px;
}

.nf_wrapper>div{
    margin-top: 25px;
}

.nf_wrapper>div>button{
    padding: 10px;
    border-radius: 50px;
    outline: none;
    border: none;
    font-weight: bold;
    margin-right: 25px;
    cursor: pointer;
    font-size: 0.976vw;
    margin-bottom: 25px;
}

@media screen and (max-width:1000px) {
    .nf_wrapper{
      margin-top: 10px;
    }
   }

   @media only screen and (orientation:portrait){

    .nf_wrapper{
        margin-top: 19vh;
    }

    .nf_wrapper h1{
        font-size: 2.343vh;
    }
    
    .nf_wrapper h2{
        font-size: 1.756vh;
    }
    .nf_wrapper>div>button{
        font-size: 0.976vh;
    }
}
@media screen and (orientation:landscape) and (max-width:1000px) {
    .nf_wrapper{
        margin-top: 29vh;
    }
}

@media screen and (max-height:400px) {
    .nf_wrapper{
        margin-top: 31vh;
    }
  }

  @media screen and (max-height:300px) {
    .nf_wrapper{
        margin-top: 41vh;
    }
  }